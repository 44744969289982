/* LIGHT */
@font-face {
  font-family: 'CampusFranceSans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('./sans/light.woff2') format('woff2'), url('./sans/light.woff') format('woff');
  font-style: normal;
}
/* REGULAR */
@font-face {
  font-family: 'CampusFranceSans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./sans/regular.woff2') format('woff2'), url('./sans/regular.woff') format('woff');
  font-style: normal;
}
/* BOLD */
@font-face {
  font-family: 'CampusFranceSans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('./sans/bold.woff2') format('woff2'), url('./sans/bold.woff') format('woff');
  font-style: normal;
}
/* LIGHT OBLIQUE*/
@font-face {
  font-family: 'CampusFranceSans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('./sans/light-oblique.woff2') format('woff2'), url('./sans/light-oblique.woff') format('woff');
  font-style: oblique;
}
/* REGULAR OBLIQUE*/
@font-face {
  font-family: 'CampusFranceSans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./sans/oblique.woff2') format('woff2'), url('./sans/oblique.woff') format('woff');
  font-style: oblique;
}
/* BOLD OBLIQUE*/
@font-face {
  font-family: 'CampusFranceSans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('./sans/bold-oblique.woff2') format('woff2'), url('./sans/bold-oblique.woff') format('woff');
  font-style: oblique;
}
