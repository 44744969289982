/* LIGHT */
@font-face {
  font-family: 'CampusFranceSerif';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('./serif/light.woff2') format('woff2'), url('./serif/light.woff') format('woff');
  font-style: normal;
}

/* REGULAR */
@font-face {
  font-family: 'CampusFranceSerif';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./serif/regular.woff2') format('woff2'), url('./serif/regular.woff') format('woff');
  font-style: normal;
}

/* BOLD */
@font-face {
  font-family: 'CampusFranceSerif';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('./serif/bold.woff2') format('woff2'), url('./serif/bold.woff') format('woff');
  font-style: normal;
}